
import React from 'react';
import logo from './logo.svg';
import './style.css';
import './scss/style.scss'
import '@coreui/coreui-pro/dist/css/coreui.min.css';


import {
    BrowserRouter,
    Routes,
    Route,
    Link,
} from "react-router-dom";

// Containers
const AppPlantilla = React.lazy(() => import('./plantilla/components/AppPlantilla'))

function App() {
    return (
        <React.Fragment>
            <BrowserRouter>
                <Routes>
                    <Route path="*" name="Home" element={<AppPlantilla />} />
                </Routes>
            </BrowserRouter>
        </React.Fragment>
  );
}

export default App;
